import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import {MetaData} from "../components/meta"

const FotosPage = ({ data }) => {
  
  const location = {
    pathname: '/fotos/',
    search:'',
    hash:''
  }
 return  (
    <Layout>
      {/* {console.log(data)} */}
      <MetaData data={data} location={location}  type="series" />
      <div className="row">
        <h6>EM DESENVOLVIMENTO</h6>
        <h4>
          Preciso das informações da equipe de comunicação do deputado para
          desenvolver esta página.
        </h4>
      </div>
      <div className="row">
        <ul className="list-group mx-auto">
          {/* {data.allAlbuns.nodes.map(album => (         
            <li
              className="list-group-item list-group-item-action"
              key={album.id}
            >
               <span>{album.title._content}</span>
              
            </li>
          ))} */}
        </ul>
      </div>
    </Layout>
  )

}

// export const query = graphql`
//   query AllAlbuns {
//     allAlbuns {
//       nodes {
//         id
//         title {
//           _content
//         }
//         description {
//           _content
//         }
//         date_create
//         count_photos
//       }
//     }
//   }
// `

export default FotosPage
